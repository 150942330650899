/* This is for global CSS*/

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.button-primary {
  @apply inline-block px-6 py-2 font-medium text-base bg-primary border-2 rounded-lg text-white hover:text-primary hover:bg-white;
  @apply font-semibold bg-white;
}

.root-app {
  @apply p-2 m-2 sm:m-5 sm:p-5;
}

/* coba override devextrem css  */
.dx-theme-generic-typography {
  font-family: Poppins, Calibri, sans-serif !important;
  font-size: 16px !important;
}

.dx-theme-generic-typography input {
  font-family: Poppins, Calibri, sans-serif !important;
}

.text-2xl {
  font-size: 28px !important;
  line-height: 1.5 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.dx-theme-generic-typography h1 {
  font-size: 14px !important;
}

.text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.label {
  @apply text-sm font-normal text-primary my-2;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.value {
  @apply text-lg font-normal text-gray-800 my-2;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}